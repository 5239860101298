import React from 'react';
import { useLocation } from 'react-router-dom';

export const BackgroundImage: React.FC = () => {
  const location = useLocation();
  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Insurance Plans for Landlords';
      case '/about':
        return 'About Us';
      case '/contact':
        return 'Contact Us';
      case '/security-deposit-insurance':
        return 'Security Deposit Protection';
      case '/tenant-legal-liability':
        return 'Tenant Legal Liability Protection';
      default:
        return '';
    }
  };

  return (
    <div 
      className="background-image" 
      style={{ backgroundImage: 'url("/images/background2.png")' }}
    >
      <h1 className='h1HeaderText'>{getTitle()}</h1>
    </div>
  );
};