import React from 'react';
import { Container, Card } from 'react-bootstrap';
// import MaterialIcon, {colorPalette} from 'material-icons-react';
// import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

export const SecurityDepositInsurance: React.FC = () => {
    return (
        <Container>
            <h2 className="mobile-only-title">Security Deposit Protection</h2>
            <div className="text-center my-4">
                <span className="material-icons">assured_workload</span>
            </div>
            <Card className="my-4">
                <Card.Body className="text-left">
                    <h2>Landlords</h2>
                    <p>In a perfect world, all renters would pay their rent and any excess wear and tear, and the landlord would not need a security deposit.
                    Unfortunately, that is not always the case, even though tenants are still obligated to the terms of their lease agreement. Through Security Deposit Protection, the landlord is covered for the unpaid bills owed by the renters at the lease end to cover excess wear, tear, and lost rent that tenants do not pay.
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
};
